import React, { useState, useEffect, createRef } from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import PageTitle from '../components/Blocks/PageTitle'
const queryString = require('query-string')

const bodyTypeMappings = {
  suv: 'SUV',
  wagon: 'Wagon',
  convertible: 'Convertible',
  sedan: 'Sedan',
  coupe: 'Coupe',
  hatchback: 'Hatchback',
  truck: 'Truck',
  van: 'Van',
}

const ChooseACar = ({
  title,
  content,
  vehicles,
  page,
  allBodyTypes,
  location,
}) => {
  const queryParams = queryString.parse(location.search)

  const { chooseACarCta2, chooseACarCta1 } = page
  const [results, setResults] = useState([])
  const [bodyType, setBodyType] = useState(queryParams.body)
  const [make, setMake] = useState(null)
  const [model, setModel] = useState(null)
  const [lastChanged, setLastChanged] = useState(null)

  const [resultType, setResultType] = useState(null)

  const resultsRef = createRef()

  const handleSubmit = () => {
    setBodyType(null)
    let vehicleNodes = vehicles.nodes

    if (make && !model) {
      let result = vehicleNodes.find(node => node.id === make)
      setAndShowResults(result.wpChildren?.nodes)
    } else if (model) {
      let splitIds = model.split('-')
      //brand-model
      let brand = vehicleNodes.find(node => node.id === splitIds[0])
      let result = brand.wpChildren?.nodes.find(node => node.id === splitIds[1])
      setAndShowResults([result])
    } else {
      setResults([])
    }
  }

  const setAndShowResults = items => {
    setResults(items)
    resultsRef.current?.scrollIntoView()
  }

  useEffect(() => {
    let isCanceled = false

    const runAsync = () => {
      if (bodyType) {
        let bt = bodyTypeMappings[bodyType.toLowerCase()]
        let result = allBodyTypes?.edges.filter(
          vehicle => vehicle.node?.bodyType?.bodyType === bt
        )
        if (!isCanceled) {
          setAndShowResults(result.map(r => r.node))
        }
      }
    }

    runAsync()

    return () => {
      isCanceled = true
    }
  }, [bodyType])

  return (
    <div>
      <PageTitle title={title} />
      <div
        className="transition-all"
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <div className="mt-8 p-8 md:p-20 max-w-screen-xl mx-auto">
        <h2 className="font-light uppercase">SEARCH FOR CARS</h2>
        <form className="flex flex-col md:flex-row justify-center gap-x-10 gap-y-5 mt-10">
          <select
            className="border py-2 border-gray-500"
            onChange={e =>
              setMake(e.target.value === 'null' ? null : e.target.value)
            }
          >
            <option value={'null'}>Vehicle Make</option>
            {vehicles?.nodes.map(brand => (
              <option value={brand.id}>{brand.title}</option>
            ))}
          </select>

          <select
            className="border py-2 border-gray-500"
            onChange={e =>
              setModel(e.target.value === 'null' ? null : e.target.value)
            }
          >
            <option value="null">Vehicle Model</option>

            {make && make !== 'Vehicle Make'
              ? vehicles?.nodes
                  .filter(node => node.id === make)
                  .map(brand => (
                    <optgroup
                      label={brand.title.replace('Vehicles', '')}
                      key={brand.id}
                    >
                      {brand.wpChildren?.nodes.map(model => (
                        <option
                          value={`${brand.id}-${model.id}`}
                          data-brand={brand.id}
                        >
                          {model.title.replace('Leasing', '')}
                        </option>
                      ))}
                    </optgroup>
                  ))
              : vehicles?.nodes.map(brand => (
                  <optgroup
                    label={brand.title.replace('Vehicles', '')}
                    key={brand.id}
                  >
                    {brand.wpChildren?.nodes.map(model => (
                      <option
                        value={`${brand.id}-${model.id}`}
                        data-brand={brand.id}
                      >
                        {model.title.replace('Leasing', '')}
                      </option>
                    ))}
                  </optgroup>
                ))}
          </select>
          <button type="button" className="btn" onClick={handleSubmit}>
            Submit
          </button>
        </form>
        <div className="my-20">
          <h3 className="uppercase text-brand-blue text-center">
            Shop by body style
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-20 justify-items-center">
            <button onClick={() => setBodyType('Sedan')}>
              <img
                src="https://dev.dmautoleasing.com/wp-content/uploads/2019/06/Sedan-compress.png"
                className="max-w-xs w-full"
              />
              <p className="mt-4 hover:text-brand-green transition-all">
                Sedan
              </p>
            </button>
            <button onClick={() => setBodyType('Wagon')}>
              <img
                src="https://dev.dmautoleasing.com/wp-content/uploads/2019/06/Wagon-compress.png"
                className="max-w-xs w-full"
              />
              <p className="mt-4 hover:text-brand-green transition-all">
                Wagon
              </p>
            </button>
            <button onClick={() => setBodyType('SUV')}>
              <img
                src="https://dev.dmautoleasing.com/wp-content/uploads/2019/06/SUV-compress.png"
                className="max-w-xs w-full"
              />
              <p className="mt-4 hover:text-brand-green transition-all">SUV</p>
            </button>
            <button onClick={() => setBodyType('Coupe')}>
              <img
                src="https://dev.dmautoleasing.com/wp-content/uploads/2019/06/Coupe-compress.png"
                className="max-w-xs w-full"
              />
              <p className="mt-4 hover:text-brand-green transition-all">
                Coupe
              </p>
            </button>
            <button onClick={() => setBodyType('Hatchback')}>
              <img
                src="https://dev.dmautoleasing.com/wp-content/uploads/2019/06/Hatchback-compress.png"
                className="max-w-xs w-full"
              />
              <p className="mt-4 hover:text-brand-green transition-all">
                Hatchback
              </p>
            </button>
            <button onClick={() => setBodyType('Convertible')}>
              <img
                src="https://dev.dmautoleasing.com/wp-content/uploads/2019/06/Convertible-compress.png"
                className="max-w-xs w-full"
              />
              <p className="mt-4 hover:text-brand-green transition-all">
                Convertible
              </p>
            </button>
            <button onClick={() => setBodyType('Truck')}>
              <img
                src="https://dev.dmautoleasing.com/wp-content/uploads/2019/06/Truck-compress.png"
                className="max-w-xs w-full"
              />
              <p className="mt-4 hover:text-brand-green transition-all">
                Truck
              </p>
            </button>
            <button onClick={() => setBodyType('Van')}>
              <img
                src="https://dev.dmautoleasing.com/wp-content/uploads/2019/06/Van-compress.png"
                className="max-w-xs w-full"
              />
              <p className="mt-4 hover:text-brand-green transition-all">Van</p>
            </button>
          </div>
        </div>

        <div id="results" ref={resultsRef}>
          {results?.length > 0 && (
            <div className="mt-10">
              <h3>{results.length} Vehicles Found</h3>
              {results.map(result => (
                <Link to={result.uri} className="">
                  <div className="flex items-center gap-x-10 hover:opacity-75 transition-all border  mb-4 hover:border-black">
                    <img
                      src={result.featuredImage?.node.mediaItemUrl}
                      className="w-36 h-36 object-contain"
                    />
                    <p className="font-bold text-2xl">{result.title}</p>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>

        <div className="flex flex-col mt-20 items-center jusitfy-center">
          <div className="max-w-md">
            <h3 className="text-brand-green text-center">
              {chooseACarCta1?.sectionTitle}
            </h3>
            <div
              className="text-center"
              dangerouslySetInnerHTML={{ __html: chooseACarCta1?.leasingLinks }}
            />
            <img
              src={chooseACarCta1?.image?.mediaItemUrl}
              className="w-full "
            />
            <a href="#quoteForm" className="text-center btn w-full block">
              Get a quote
            </a>
          </div>
        </div>
      </div>
      <div className="bg-brand-green w-full p-8 md:p-20">
        <p className="font-bold text-2xl">{chooseACarCta2?.sectionTitle}</p>
        <div
          className="text-left text-lg mb-8"
          dangerouslySetInnerHTML={{ __html: chooseACarCta2?.text }}
        />
        <Link
          to="/car-lease-deals/"
          className="btn border text-black border-black"
        >
          Shop Deals
        </Link>
      </div>
    </div>
  )
}

ChooseACar.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data, location, pageContext }) => {
  const { wpPage: page, allWpPage, allBodyTypes } = data

  return (
    <Layout pageContext={pageContext}>
      <Seo seo={page.seo} />
      <ChooseACar
        title={page.title}
        content={page.content}
        vehicles={allWpPage}
        page={page}
        allBodyTypes={allBodyTypes}
        location={location}
      />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query ChooseACar($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      seo {
        fullHead
        schema {
          raw
        }
      }
      chooseACarCta1 {
        fieldGroupName
        leasingLinks
        sectionTitle
        image {
          id
          mediaItemUrl
        }
      }
      chooseACarCta2 {
        fieldGroupName
        sectionTitle
        text
      }
    }
    allBodyTypes: allWpPage(filter: { bodyType: { bodyType: { ne: null } } }) {
      edges {
        node {
          id
          title
          uri
          featuredImage {
            node {
              mediaItemUrl
            }
          }
          bodyType {
            bodyType
          }
        }
      }
    }
    allWpPage(
      filter: { parentDatabaseId: { eq: 248 } }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        title
        uri
        id
        wpChildren {
          nodes {
            uri
            ... on WpPage {
              id
              title
              featuredImage {
                node {
                  id
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    }
  }
`
